.beneficios {
    display: flex;
    gap: 40px;
    width: 100%;
    position: relative;
}

.persona_tablet {
    width: 65%;
    height: 777px;
    display: flex;
    position: relative;
    border-radius: 30px;
    background-size: cover;
    float: left;
  }

.persona_tablet::before {
    content: "";
    background-color: rgba(31, 59, 76, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.7;
    border-radius: 30px;
}

.texto_imagen_beneficio {
    font-family: "Poppins";
    font-style: normal;
    font-size: 40px;
    line-height: 60px;
    color: #ffffff;
    display: inline;
    padding: 60px 100px;
    position: relative;
    align-content: end;
  }

.grid_container_beneficios {
    display: grid;
    gap: 50px !important;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.circulos1 {
    margin: 40px 0;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: rgba(52, 130, 197, 0.2);
}

.circulos2 {
    width: 41px;
    height: 41px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(52, 130, 197, 0.5);
}

.circulos3 {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background: #3482c5;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }


@media screen and (min-width: 0) and (max-width: 767px) {

  
    .medio_circulo {
      float: none;
      width: 160px;
      height: 355px;
      bottom: auto;
    }
  
    .texto_imagen_beneficio {
      display: flex;
      flex-flow: column;
      justify-content: end;
      width: fit-content;
      text-align: justify;
      font-size: 2.6rem;
      line-height: 5rem;
      padding: 30px;
    }
  
  }
  
  @media screen and (min-width: 768px) {
    .container {
      width: 750px;
    }
  }
  
  @media screen and (max-width: 992px) {
    .beneficios {
      flex-direction: column;
      padding: 0;
    }
  
    .persona_tablet {
      width: 100% !important;
      left: 0;
      background-position: center;
    }
  
    .grid_container_beneficios {
      padding: 30px;
    }
  
    .circulo_hueco {
      display: none;
    }
  }
  
  @media screen and (min-width: 992px) {
  
    @media screen and (max-width: 1111.5px) {
      /* .beneficios {
        position: relative;
        top: -400px;
      } */
  
      /* .circulo_raya {
        position: relative;
        top: -200px;
      } */
  
      .persona_tablet {
        margin-left: 0px;
      }
    }
  
    .container {
      width: 970px;
    }
  
    .grid_container_beneficios {
      padding: 0;
      gap: 0px;
    }
  
    .grid_container_beneficios>div {
      padding: 0px 0px;
    }
  
    .persona_tablet {
      aspect-ratio: 9/16;
      width: 50%;
      height: auto;
      left: 0;
      margin-left: 0px;
    }
  
    .beneficios {
      justify-content: center;
    }
  }
  
  @media screen and (min-width: 1112px) {
    .persona_tablet {
      margin-left: 70px;
    }
  }
  
  @media (min-width: 1200px) {
    .container {
      width: 100%;
    }
  }
  
  @media screen and (min-width: 1200px) and (max-width: 1328px) {
    /* .beneficios {
      position: relative;
      top: -400px;
      justify-content: center;
    } */
  
    /* .circulo_raya {
      position: relative;
      top: -200px;
    } */
  
    .persona_tablet {
      margin-left: 0px;
    }

  }
  
  @media screen and (min-width: 1311px) {
    .persona_tablet {
      margin-left: 70px;
    }
  }

  .circulo_hueco {
    position: absolute;
    right: 0px;
    top: -419px;
  }

  @media screen and (max-width: 1200px) {
    .circulo_hueco {
      right: -111px;
      top: -419px;
    }
  }

  @media screen and (max-width: 1080px) {
    .circulo_hueco {
      right: -55px;
      top: -419px;
    }
  }
