.servicios {
    margin-top: 5rem !important;
}

.grid_container_servicios {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.container_servicios {
    background: #203b4c;
    border-radius: 18px;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.container_servicios:hover>.capa_imagen {
    opacity: 0.2;
    visibility: visible;
}

.circulos4 {
    width: 69px;
    height: 68px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: #3482c5;
    margin: 40px 50px;
}

.subtitulos-servicios {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
    padding-left: 20px;
    padding: 0px 50px 0px 50px;
}

.contenido-servicios {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    color: #ffffff;
    padding: 15px 50px 50px 50px;
    position: relative;
}

.capa_imagen {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    visibility: hidden;
    text-align: center;
    background: rgba(32, 59, 76, 0.8);
} 

.circulo_raya {
    position: absolute;
    left: 0;
    z-index: -1;
}

@media (max-width: 992px) {

    .circulo_raya {
        left: -94px;
        bottom: -211px;
    } 

}

@media (max-width: 810px) {

    .circulo_raya {
        left: -32px;
        bottom: -211px;
    } 

}

@media (max-width: 760px) {

    .circulo_raya {
        left: 0;
        bottom: -211px;
    } 

}

@media (max-width: 400px) {
    .circulo_raya {
      left: -29px;
      bottom: -211px;
    }
}

.img_servicios {
    width: 100%;
    height: 100%;
  }