.footer {
    position: absolute;
    margin-top: 200px;
    width: 100%;
    height: auto;
    background: #203b4c;
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }
  
  .footer .grupo-2 {
    width: 100%;
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    justify-content: space-between;
    order: 2;
  }
  
  .footer .grupo-2 .box-1 {
    margin-right: 50px;
    order: 1;
  }
  
  .footer .grupo-2 .box-1 figure {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer .grupo-2 .box-1 figure img {
    width: 250px;
  }
  
  .footer .grupo-2 .box-1 p,
  .box-3 p {
    color: #ffffff;
    margin-top: 15px;
    font-family: "Poppins";
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: justify;
  }

  .footer .grupo-2 .box-3 {
    justify-items: end;
    display: grid;
    margin-left: auto;
    order: 3;
    font-weight: bolder;
  }
  
  .footer .grupo-2 .box-2 {
    order: 2;
  }
  
  .footer .grupo-1 {
    order: 1;
  }
  
  .footer .redes-sociales {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    gap: 50px;
  }
  
  .footer hr {
    vertical-align: middle;
    width: 100%;
    display: inline-block;
    border: 1px solid #ffffff;
  }
  
  .footer .grupo-3 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 50px;
    justify-content: space-between;
    order: 3;
    margin-top: 40px;
  }
  
/* Responsive para Footer */

@media screen and (min-width: 0) and (max-width: 768px) {
    .footer .grupo-2 {
      width: 90%;
      margin: 10px;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 45px;
      padding: 45px 0px;
    }
  
    .footer .grupo-2 .box-1,
    .footer .grupo-2 .box-3 {
      margin-left: 30px;
      margin-right: 0px;
    }
  
    .footer .redes-sociales {
      gap: 30px;
    }
  
    .footer .grupo-2 .box-1 {
      order: 2;
    }
  
    .footer .grupo-2 .box-3 {
      order: 3;
      justify-items: initial;
      margin-left: auto;
      margin-right: auto;
      padding-left: 30px;
      width: 100%;
    }
  
    .footer .grupo-2 .box-2 {
      order: 1;
    }
  
    .footer .grupo-1 {
      order: 3;
    }
  }

  