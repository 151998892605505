* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

body {
  background-color: #ffffff;
}

.container_card {
  overflow: hidden;
  position: relative;
  bottom: 100px;
}

.container_card_home {
  display: flex;
  width: 200%;
}

/* .card_primary {
  flex-shrink: 0;
  position: relative;
  display: grid;
  place-items: center;
  height: 223px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
} */

/* .card_primary var {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #727272;
} */

.medio_circulo {
  float: left;
  background: linear-gradient(122deg,
      rgba(31, 59, 76, 0.16) 13.93%,
      rgba(52, 130, 197, 0.2) 86.49%);
  width: 439px;
  height: 439px;
  shape-outside: circle(0% at 10%);
  clip-path: circle(50% at 0%);
  position: relative;
  bottom: 250px;
}

.titulos {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: #203b4c;
  margin-bottom: 3rem !important;
}

.subtitulos {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #203b4c;
  margin-bottom: 28px;
}

.grid_container_work {
  display: inline-grid;
  gap: 90px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-bottom: 130px;
  margin-top: 20px;
}



.grid_container_contactos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 30px;
}

.grid_container_clientes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 30px;
}

/* Como trabaja en avanza */

.contenido {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #727272;
}

.as-work {
  text-align: center;
  margin-bottom: 3rem !important;
  margin-top: 10rem !important;
}

.icons-as-work {
  margin-bottom: 20px;
}

/* Beneficios */

.circulo_hueco {
  position: relative;
  float: right;
  top: -250px;
}

.contenido_beneficios .subtitulos {
  min-height: 80px;
  text-align: justify;
  margin-bottom: 12px;
}

/* Servicios */


.circulo_raya {
  float: left;
}




/* Cliente */

.clientes {
  margin-top: 5rem !important;
}

.container_slider {
  display: grid;
  align-content: center;
  text-align: center;
  justify-content: center;
  margin-top: 3rem !important;
}

.clientes .slider_cont {
  align-items: center;
  margin-top: 100px;
}

.container_cliente {
  overflow: hidden;
}

.container_card_cliente {
  display: flex;
}

.swiper_cliente {
  width: calc(4 * 274px);
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: linear;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function,
      initial);
  box-sizing: content-box;
}

.contenido_clientes {
  border: #203B4C solid 1px;
  background: transparent;
  border-radius: 18px;
  flex-shrink: 0;
  height: 141px;
  width: 253px;
  position: relative;
  /* display:block; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.logos_clientes {
  width: 60%;
}

.swiper-button-prev,
.swiper-button-next img {
  cursor: pointer;
}

/* Contactos */

.contactos {
  margin-top: 5rem !important;
}

/* Responsive */

@media screen and (min-width: 0) and (max-width: 767px) {
  .card {
    bottom: -960px;
  }

  .swiper_valores {
    width: calc(1 * 230px);
  }

  .logo_avanza {
    width: 50%;
    height: 40%;
  }

  .container_card {
    bottom: -100px;
  }


  .circulo_raya {
    float: none;
    position: relative;
    bottom: 100px;
  }

  .as-work {
    margin-top: 0rem !important;
  }

  .texto_imagen_beneficio {
    display: flex;
    flex-flow: column;
    justify-content: end;
    width: fit-content;
    text-align: justify;
    font-size: 2.6rem;
    line-height: 5rem;
    padding: 30px;
  }

  .clientes {
    margin-top: 5rem !important;
  }

}

@media screen and (min-width: 768px) {
  .container {
    width: 750px;
  }

  .botenes_slider {
    position: relative;
    padding: 0;
  }

  .swiper_valores {
    width: calc(2 * 230px);
  }

  .swiper_cliente {
    width: calc(2 * 274px);
  }

  .container_card {
    bottom: -100px;
  }

  .as-work {
    margin-top: 15rem !important;
  }
}

@media screen and (max-width: 992px) {

  .header_itemCarrouselTarjeta {
    font-size: 40px;
    text-align: center;
    padding: 20px;
  }

  .itemCarrouselTarjeta {
    font-size: 40px;
    text-align: center;
    padding: 0;
  }

  .swiper_banner {
    height: 100vh;
  }

  .swiper_cliente {
    width: calc(2 * 274px);
  }

  .swiper_valores {
    width: calc(2 * 230px);
  }

  .container_card {
    bottom: -100px;
  }

  .grid_container_work {
    padding: 30px;
  }

  .beneficios {
    flex-direction: column;
    padding: 0;
  }

  .persona_tablet {
    width: 100% !important;
    left: 0;
    background-position: center;
  }

  .grid_container_beneficios {
    padding: 30px;
  }

  .circulo_hueco {
    display: none;
  }
}

@media screen and (min-width: 992px) {

  @media screen and (max-width: 1111.5px) {
    /* .beneficios {
      position: relative;
      top: -400px;
    } */

    .servicios {
      position: relative;
      top: -300px;
    }

    .clientes {
      position: relative;
      top: -150px;
    }

    .circulo_raya {
      position: relative;
      top: -200px;
    }

    .persona_tablet {
      margin-left: 0px;
    }
  }

  /* .container {
    width: 970px;
  } */

  .itemCarrouselArrows {
    top: 100px;
  }

  .swiper_valores {
    width: calc(3 * 230px);
  }

  .swiper_cliente {
    width: calc(3 * 274px);
  }

  .container_card {
    bottom: -100px;
  }

  .grid_container_beneficios {
    padding: 0;
    gap: 0px;
  }

  .grid_container_beneficios>div {
    padding: 0px 0px;
  }

  .persona_tablet {
    aspect-ratio: 9/16;
    width: 50%;
    height: auto;
    left: 0;
    margin-left: 0px;
  }

  .beneficios {
    justify-content: center;
  }
}

@media screen and (min-width: 1112px) {
  .persona_tablet {
    margin-left: 70px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 100%;
  }

  #boton_izquierda_card,
  #boton_derecha_card {
    visibility: hidden;
  }

  .swiper_valores {
    width: calc(6 * 230px);
  }

  .swiper_cliente {
    width: calc(4 * 274px);
  }

  .container_card {
    bottom: 100px;
  }

  .as-work {
    margin-top: 3rem !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1328px) {
  /* .beneficios {
    position: relative;
    top: -400px;
    justify-content: center;
  } */

  .servicios {
    position: relative;
    top: -300px;
  }

  .clientes {
    position: relative;
    top: -150px;
  }

  .circulo_raya {
    position: relative;
    top: -200px;
  }

  .persona_tablet {
    margin-left: 0px;
  }

  .card_primary {
    padding: 0.5rem;
  }

  .card_primary var {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1311px) {
  .persona_tablet {
    margin-left: 70px;
  }
}



