  .contactos {
    margin-top: 5rem;

  }

  .grid_container_contactos {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 30px;
  }

  .contenido_contactos {
    display: grid;
    place-content: center;
    text-align: center;
  }

  .container_icon_contactos {
    display: grid;
    place-content: center;
  }
  
  .circulo_icon_contactos {
    background: #33ac85;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 130px;
    height: 130px;
  }

  .texto_contacto {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    margin: 20px 5px;
    color: #727272;
  }
  
  .texto_contacto_titulo {
    font-weight: 700;
    font-size: 18px;
    color: #203b4c;
  }
  
  .btn_contactos {
    border-radius: 9px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #203b4c;
    border: none;
    padding: 15px 0px;
    width: 188px;
    display: inline-block;
    background: rgba(32, 59, 76, 0.1);
    text-decoration: none;
  }
  
  .btn_contactos:hover {
    background: #203b4c;
    color: #ffffff;
  }
  