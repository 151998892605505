.swiper_banner {
  width: 100%;
  overflow: hidden;
  height: 120vh;
  margin-left: auto;
  margin-right: auto;
}

.logos_header {
  position: absolute;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 56px;
}

.redes_sociales_header {
  display: flex;
  gap: 20px;
  text-decoration: none;
}


/* LETRAS EN HEADER */
.header_itemCarrouselTarjeta {
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  line-height: 90px;
  text-align: right;
  color: #ffffff;
  padding: 0 200px;
}

.itemCarrouselArrows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  padding: 20px;
}

.header_swiper-button-prev,
.header_swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.header_swiper-button-prev {
  left: 10px;
}

.header_swiper-button-next {
  right: 10px;
}

.header_swiper-button-prev img, .header_swiper-button-next img {
  width: 50px;
  height: 50px;
}

.header_swiper-button-prev::after, .header_swiper-button-next::after {
  display: none;
}

.header_swiper-button-prev, .header_swiper-button-next {
  background-size: contain; 
  background-repeat: no-repeat; 
}

.swiper-header {
  height: 120vh;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  
  .header_swiper-button-prev img, .header_swiper-button-next img {
    width: 30px;
    height: 30px;
  }

  .logos_header {
    padding: 10px;
  }

}

@media screen and (max-width: 992px) {

  .logos_header {
    padding: 10px;
  }

}


