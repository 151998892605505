
.slider_cont {
  display: flex;
  align-items: end;
  gap: 5px;
}

.cards_swiper_valores {
  width: calc(6 * 230px);
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5px;
  padding-right: 5px;
}

.cards_swiper-wrapper {
  overflow: visible !important;
  display: flex;
  justify-content: center; 
}

.cards_itemCarrouselTarjeta {
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  line-height: 90px;
  text-align: right;
  color: #ffffff;
  padding: 0 300px;
}

.cards_card_primary {
  flex-shrink: 0;
  position: relative;
  display: grid;
  place-items: center;
  height: 223px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.cards_card_primary var {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #727272;
  margin-top: 17px;
}

.cards_swiper-button-prev, .cards_swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.cards_swiper-button-prev {
  /* left: 10px; */
  display: none;

}

.cards_swiper-button-next {
  /* right: 10px; */
  display: none;

}

.medio_circulo {
  position: absolute;
  left: 0;
  top: 0;
  width: 439px;
  height: 439px;
  background: linear-gradient(122deg, rgba(31, 59, 76, 0.16) 13.93%, rgba(52, 130, 197, 0.2) 86.49%);
  shape-outside: circle(0% at 10%);
  clip-path: circle(50% at 0%);
}

@media screen and (min-width: 1200px) and (max-width: 1328px) {
  .cards_card_primary {
    padding: 0.5rem;
  }
 av
  .cards_card_primary var {
    font-size: 1rem;
  }
}

.medio_circulo {
  position: absolute; 
  left: -36px;
  top: -12px;
  width: 439px !important;
  height: 439px !important;
  background: linear-gradient(122deg, rgba(31, 59, 76, 0.16) 13.93%, rgba(52, 130, 197, 0.2) 86.49%);
  clip-path: circle(50% at 0% 50%); 
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .medio_circulo {
    left: -127px;
    top: 97px;
    width: 439px;
    height: 439px;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .medio_circulo {
    left: -127px;
    top: -97px;
    width: 439px;
    height: 439px;
  }
}

.container {
  position: relative; 
  width: 100%; 
  height: auto; 
}
