.clientes {
    margin-top: 5rem !important;
  }

  .container_slider {
    display: grid;
    align-content: center;
    text-align: center;
    justify-content: center;
    margin-top: 3rem !important;
  }

  .container_card_cliente {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
  }
  

  .contenido_clientes {
    border: #203B4C solid 1px;
    background: transparent;
    border-radius: 18px;
    flex-shrink: 0;
    height: 141px;
    width: 253px;
    margin: 10px; 
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .logos_clientes {
    width: 60%;
  }

@media (max-width: 768px) {
  .container_card_cliente {
    flex-direction: column; 
  }

  .contenido_clientes {
    height: 250px;
    width: 80%; 
    margin: 10px auto; 
  }

  .logos_clientes {
    width: 43%;
  }
}

