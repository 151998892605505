.as-work {
    text-align: center;
    margin-bottom: 3rem !important;
    margin-top: 10rem !important;
  }

.grid_container_work {
  display: inline-grid;
  gap: 90px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-bottom: 130px;
  margin-top: 20px;
}

.icons-as-work {
    margin-bottom: 20px;
}
